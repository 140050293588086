<div class="row" *ngIf="showNotices">
  <div class="col-12">
    <div class="row">
      <div>
        <div class="alert alert-warning d-flex align-items-center" role="alert">
          <div>
            ⚠ Due to a bug in the chia codebase <strong>block forging is currently impacted</strong>.
            Chia nodes running 2.5.0 and below might stop producing blocks until restarted, and can stop producing blocks again later.
            <br><br>
            <strong>
              Please upgrade to <a href="{{downloadUrl}}" target="_blank">2.5.1</a> as soon as possible, as the pool rejects all partials from older versions!
            </strong> Foxy-Farmer users do not need to update 👍
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <app-stats-card class="col-md-12 col-lg-6"></app-stats-card>
  <app-pool-history class="col-md-12 col-lg-6"></app-pool-history>
</div>
<div class="row">
  <div class="col-lg-12 col-xl-4 col-xxl-4 col-xxxl-9">
    <app-top-accounts></app-top-accounts>
  </div>
  <div class="col-lg-12 col-xl-8 col-xxl-8 col-xxxl-15">
    <app-blocks-won limit=10></app-blocks-won>
  </div>
</div>
<app-payouts limit=5></app-payouts>
